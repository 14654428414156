import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpHeaders,
  HttpEvent,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, share, switchMap, take } from "rxjs/operators";
import { AuthService } from "../auth.service";
import { FeatureFlagService } from "src/app/layouts/layout/core/services/feature-flag.service";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  currenttoken: any;
  currentRefreshToken: any;
  userType: any;
  constructor(
    private authService: AuthService,
    private readonly featureFlagService: FeatureFlagService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authService.tokenExpired()) {
      this.SessionExpired();
    }
    req = req.clone();

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes("auth/login") &&
          error.status === 401
        ) {
          this.authService.logout();
        }
        return throwError(error);
      })
    );
    return next.handle(req);
  }
  private SessionExpired() {
    return this.authService.logout();
  }
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      if (this.authService.isLoggedIn()) {
        const _refreshToken = this.authService.getRfreshTokenFromLocalStorage();
        return this.authService.refreshToken(_refreshToken, this.userType).pipe(
          switchMap((response) => {
            if (response) {
              this.isRefreshing = false;
              this.currenttoken = response.json().access_token;
              this.currentRefreshToken = response.json().refresh_token;
              localStorage.clear();
              localStorage.setItem("access_token", this.currenttoken);
              localStorage.setItem("refresh-token", this.currentRefreshToken);
              this.refreshTokenSubject.next(this.currenttoken);
              return next.handle(
                this.addTokenHeader(request, this.currenttoken)
              );
            }
          }),
          catchError((error) => {
            this.isRefreshing = false;
            if (error.status == "403") {
              this.authService.logout();
              this.featureFlagService.start();
            }

            return throwError(error);
          })
        );
      }
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }
}
