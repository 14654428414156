import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { IMutableContext, IToggle, UnleashClient } from "unleash-proxy-client";

@Injectable({
  providedIn: "root",
})
export class FeatureFlagService {
  unleash = new UnleashClient({
    url: environment.featurFlagConfigs.url,
    clientKey: environment.featurFlagConfigs.key,
    appName: environment.featurFlagConfigs.appName,
    environment: environment.production ? "production" : "staging",
  });

  constructor() {}

  start(): void {
    this.unleash.start();
  }

  stop(): void {
    this.unleash.stop();
  }

  updateContext(contextObj: IMutableContext): void {
    this.unleash.updateContext(contextObj);
  }

  isEnabled(feature: string): boolean {
    return this.unleash.isEnabled(feature);
  }
  getAllflags(): IToggle[] {
    return this.unleash.getAllToggles();
  }
}
