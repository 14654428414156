import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { TranslationService } from './modules/i18n'
// language list
import { locale as enLang } from './modules/i18n/vocabs/en'
import { locale as chLang } from './modules/i18n/vocabs/ch'
import { locale as esLang } from './modules/i18n/vocabs/es'
import { locale as jpLang } from './modules/i18n/vocabs/jp'
import { locale as deLang } from './modules/i18n/vocabs/de'
import { locale as frLang } from './modules/i18n/vocabs/fr'
import { ThemeModeService } from './layouts/partials/layout/theme-mode-switcher/theme-mode.service'
import { AuthService } from './modules/auth/services/auth.service'
import { interval } from 'rxjs'
import { NavigationCancel, NavigationStart, Router } from '@angular/router'
import jwt_decode from 'jwt-decode'
import { FeatureFlagService } from './layouts/layout/core/services/feature-flag.service'
import { environment } from 'src/environments/environment'

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  isLoggedIn: boolean
  storageAreaToken: any
  userType: boolean
  user$: any
  userId: any
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private authService: AuthService,
    private router: Router,
    private readonly featureFlagService: FeatureFlagService,
  ) {
    this.user$ = this.authService.getCurrentUser()
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang,
    )
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        // Your code
        // Use (event.url) to get URL that is being navigated
        // this.initData();
      }
    })
    this.authService.isLoggedIn() && environment.production ? (this.userId = this.user$.user_id) : null
  }

  ngOnInit() {
    this.modeService.init()
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('access_token')
        if (token == undefined) {
          this.authService.logout()
        } else {
          this.storageAreaToken = jwt_decode(event.storageArea.access_token)
          this.authService.currentUserSubject.subscribe((user: any) => {
            if (this.storageAreaToken?.user_id !== user?.user_id) {
              window.location.reload()
            }
          })
        }
      }
    })
    if (environment.production && this.authService.isLoggedIn()) {
      this.featureFlagService.start()
      this.featureFlagService.updateContext({ userId: this.userId.toString() })
    }
  }
  initData() {
    const isTokenExpired = this.isExpired()
    if (isTokenExpired) {
      const _refreshToken = this.authService.getRfreshTokenFromLocalStorage()
      this.authService.refreshToken(_refreshToken, null)
    } else {
      this.loadPages()
    }
  }
  refreshTokenChecker() {
    // const checkPeriodAmount = 1000 * 60 * 2
    // const $checkPeriod = interval(checkPeriodAmount)
    // $checkPeriod.subscribe(() => {
    const isTokenExpired = this.isExpired()
    if (isTokenExpired) {
      const _refreshToken = this.authService.getRfreshTokenFromLocalStorage()
      this.authService.refreshToken(_refreshToken, null)
    }
    // })
  }
  isExpired(): boolean {
    this.isLoggedIn = this.authService.isLoggedIn()
    const currentDateTime = Math.floor(new Date().getTime() / 1000)
    const expirationDate = Math.floor(this.authService.getExperationDate())
    if (!this.isLoggedIn) {
      return false
    }
    const diff: number = expirationDate - currentDateTime
    const safeExpirationDate = 60 * 2
    if (diff <= safeExpirationDate) {
      return true
    }
    return false
  }
  loadPages() {
    this.isLoggedIn = this.authService.isLoggedIn()

    if (this.isLoggedIn) this.refreshTokenChecker()
  }
}
