import {  SET_PERMISSIONS,SET_EMAIL,SET_TOKEN } from './app.actions'


export interface IAppState {
    permissions?: string[]
    email?: string
    token?: string
  }
  
  export const INITIAL_STATE: IAppState = {
    permissions: [],
    email : '',
    token : ''
  }
  class AppImpl {
    constructor(private state: IAppState, private action) {}
    /** Implementation of AppActions */

    fetch_permissions() {
      return {
        ...this.state,
        permissions: this.action.val,
      }
    }
    fetch_email() {
      return {
        ...this.state,
        email: this.action.val,
      }
    }
    fetch_token() {
      return {
        ...this.state,
        token: this.action.val,
      }
    }
  }
  /** Implementation of AppReducer */
  export function AppReducer(state = INITIAL_STATE, action) {
    const appImpl = new AppImpl(state, action)
  
    switch (action.type) {
      case SET_PERMISSIONS:
        return appImpl.fetch_permissions()
        
      case SET_EMAIL:
        return appImpl.fetch_email()
      case SET_TOKEN:
        return appImpl.fetch_token()
    }
  }