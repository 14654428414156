import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, of, Subscription } from "rxjs";
import { map, catchError, switchMap, finalize } from "rxjs/operators";
import { UserModel } from "../models/user.model";
import { AuthModel } from "../models/auth.model";
import { AuthHTTPService } from "./auth-http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { Http, Headers } from "@angular/http";
import jwt_decode from "jwt-decode";
import { JwtHelperService } from "@auth0/angular-jwt";
import { HttpHeaders } from "@angular/common/http";
declare let $: any;
export type UserType = UserModel | undefined;

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  // private fields
  private headers = new Headers({ "Content-Type": "application/json" });
  baseUrlAuth = environment.backend.baseUrlIam;
  private serviceSignIn = "/oauth2/sign_in";
  private serviceRefresh = "/oauth2/refresh_token";
  private serviceForgetPwd = "/oauth2/password/forgot";
  private serviceValidateWithOTP = "/oauth2/password/forgot/confirm";
  private serviceResetPassword = "/oauth2/password/reset";
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  helper = new JwtHelperService();
  currentRefreshToken: any;
  currenttoken: any;
  date: Date_token;
  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  user$: any;
  sessionOut = new BehaviorSubject(false);
  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private http: Http,
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string) {
    this.isLoadingSubject.next(true);
    return this.http
      .post(
        `${this.baseUrlAuth}` + this.serviceSignIn,
        JSON.stringify({
          email: email,
          password: password,
        }),
        { headers: this.headers }
      )
      .pipe(
        map((response) => {
          if (response.status === 200) {
            // login successful if there's a jwt token in the response
            const currenttoken = response.json().access_token;
            const currentRefreshToken = response.json().refresh_token;
            localStorage.setItem("access_token", currenttoken);
            this.currentUserSubject.next(jwt_decode(currenttoken));
            localStorage.setItem("refresh-token", currentRefreshToken);
            // this.getPermissions();
            return true;
          } else {
            return false;
          }
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }
  isLoggedIn(): boolean {
    const token = localStorage.getItem("access_token");
    return token && token.length > 0 ? true : false;
  }
  tokenExpired() {
    const token = this.getToken();
    return this.helper.isTokenExpired(token);
  }
  getCurrentUser() {
    const token = localStorage.getItem("access_token");
    if (token) {
      const user = jwt_decode(token);
      return user;
    }
  }
  logout() {
    $('.modal').modal('hide')
    if (this.tokenExpired()) {
      this.sessionOut.next(true);
    } else {
      this.sessionOut.next(false);
    }
    localStorage.clear();
    this.router.navigate(["/auth/login"]);
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  resetPassword(object: any): Observable<any> {
    return this.http.post(
      `${this.baseUrlAuth}` + this.serviceResetPassword,
      object,
      { headers: this.headers }
    );
  }
  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error("err", err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  getRfreshTokenFromLocalStorage() {
    const token = localStorage.getItem("refresh-token");
    return token ? token : "";
  }
  getToken(): string {
    const localstorageValue = localStorage.getItem("access_token");
    return localstorageValue ? localstorageValue : "";
  }
  getExperationDate() {
    const token = this.getToken();
    this.date = jwt_decode(token);
    return this.date["exp"];
  }
  // getPermissions() {
  //   this.user$ = this.getCurrentUser();
  //   this.permissionsService.loadPermissions(
  //     this.user$["applications"][0]["permissions"]
  //   );
  //   this.rolesService.addRole(
  //     this.user$["applications"][0]["permissions"][0],
  //     this.user$["applications"][0]["permissions"]
  //   );
  //   let roles = this.rolesService.getRoles();
  // }
  forgotPassword(email: object): Observable<any> {
    return this.http.post(`${this.baseUrlAuth}` + this.serviceForgetPwd, email);
  }
  verify_account(email: string, code: string) {
    return this.http.post(
      `${this.baseUrlAuth}` + this.serviceValidateWithOTP,
      JSON.stringify({
        verification_code: code,
        email: email,
      }),
      { headers: this.headers }
    );
  }
  // refreshToken(_token: string): void  {
  //   this.http
  //     .post(
  //       `${this.baseUrlAuth}` + this.serviceRefresh,
  //       JSON.stringify({
  //         application_id: "onecloud_admin_app",
  //         refresh_token: _token,
  //       }),
  //       { headers: this.headers }
  //     )
  //     .subscribe(
  //       (response) => {
  //         this.currenttoken = response.json().access_token;
  //         this.currentRefreshToken = response.json().refresh_token;
  //         localStorage.clear();
  //         localStorage.setItem("access_token", this.currenttoken);
  //         localStorage.setItem("refresh-token", this.currentRefreshToken);
  //         return response;
  //       },
  //       (error) => {
  //         if (error.status === 401 || error.status === 403) {
  //           this.logout();
  //         }
  //       }
  //     );
  // }
  refreshToken(_token: string, userType: boolean) {
    return this.http.post(
      `${this.baseUrlAuth}` + this.serviceRefresh,
      JSON.stringify({
        application_id: this.getAppId(userType),
        refresh_token: _token,
      }),
      { headers: this.headers }
    );
  }
  getAppId(userType: boolean) {
    let $applicationId;
    switch (true) {
      case userType:
        $applicationId = "onecloud_client_app";
        break;
      default:
        $applicationId = "onecloud_admin_app";
        break;
    }
    return $applicationId;
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      return undefined;
    }
  }
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
class Date_token {
  account_verified: boolean;
  applications: [];
  aud: string;
  exp: number;
  first_name: string;
  iat: number;
  iss: string;
  last_name: string;
  sub: string;
  user_email: string;
  user_id: string;
}
